import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { PrefetchStateModel } from 'models/app.model';
import {
  listProvinceAction,
  listDistrictAction,
  listWardAction,
  listPrefetchCompanyAction,
  listPrefetchStoreAction,
  listPrefetchUserRolesAction,
  listPrefetchLayoutAction,
  listPrefetchThemeAction,
  listPrefetchThemeCategoryAction,
  listPrefetchLayoutTemplateAction,
  listPrefetchThemeTemplateAction,
  listPrefetchPromotionCampaignAction,
  listPrefetchBoothAction,
  listPrefetchStickerCategoryAction,
  listPrefetchStickerAction,
  listPrefetchStickerTemplateAction,
  listPrefetchBoothBackgroundTemplateAction,
  listPrefetchCapturePositionAction,
  listPrefetchCapturePositionTemplateAction,
  listPrefetchBoothBackgroundAction,
  listPrefetchBoothBackgroundCategoryAction,
} from './prefetch.action';

const initialState: PrefetchStateModel = {
  listProvinces: [],
  listDistricts: [],
  listWards: [],

  listCompany: [],
  listStores: [],
  listUserRoles: [],

  listBooths: [],
  listLayouts: [],
  listThemes: [],
  listThemeCategories: [],
  listThemeTemplates: [],
  listLayoutTemplates: [],
  listPromotionCampaigns: [],

  listStickers: [],
  listStickerTemplates: [],
  listStickerCategories: [],

  listBoothBackgrounds: [],
  listBoothBackgroundTemplates: [],
  listBoothBackgroundCategories: [],

  listCapturePositions: [],
  listCapturePositionTemplates: [],

  loadingProvince: false,
  loadingDistricts: false,
  loadingWards: false,
  loadingCompany: false,
  loadingStores: false,
  loadingUserRoles: false,
  loadingBooths: false,
  loadingLayouts: false,
  loadingThemes: false,
  loadingThemeCategories: false,
  loadingThemeTemplates: false,
  loadingLayoutTemplates: false,
  loadingPromotionCampaigns: false,

  loadingStickers: false,
  loadingListStickerTemplates: false,
  loadingStickerCategories: false,

  loadingBoothBackgrounds: false,
  loadingListBoothBackgroundTemplates: false,
  loadingBoothBackgroundCategories: false,

  loadingCapturePositions: false,
  loadingCapturePositionTemplates: false,
};

export const prefetchSlice = createSlice({
  name: 'prefetch',
  initialState,
  reducers: {
    setPrefetchStateAction: (
      state,
      action: PayloadAction<Partial<PrefetchStateModel>>,
    ) =>
      ({
        ...state,
        ...action?.payload,
      } as PrefetchStateModel),
    resetPrefetchStateAction: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(listProvinceAction.pending, (state) => ({
        ...state,
        loadingProvince: true,
      }))
      .addCase(listProvinceAction.fulfilled, (state, { payload }) => ({
        ...state,
        loadingProvince: false,
        listProvinces: payload?.data?.items || [],
      }))
      .addCase(listProvinceAction.rejected, (state) => ({
        ...state,
        loadingProvince: false,
      }))
      .addCase(listDistrictAction.pending, (state) => ({
        ...state,
        loadingDistricts: true,
      }))
      .addCase(listDistrictAction.fulfilled, (state, { payload }) => ({
        ...state,
        listDistricts: payload?.data?.items || [],
        loadingDistricts: false,
      }))
      .addCase(listDistrictAction.rejected, (state) => ({
        ...state,
        listDistricts: [],
        loadingDistricts: false,
      }))
      .addCase(listWardAction.pending, (state) => ({
        ...state,
        loadingWards: true,
      }))
      .addCase(listWardAction.fulfilled, (state, { payload }) => ({
        ...state,
        listWards: payload?.data?.items || [],
        loadingWards: false,
      }))
      .addCase(listWardAction.rejected, (state) => ({
        ...state,
        listWards: [],
        loadingWards: false,
      }))
      .addCase(listPrefetchCompanyAction.pending, (state) => ({
        ...state,
        loadingCompany: true,
      }))
      .addCase(listPrefetchCompanyAction.fulfilled, (state, { payload }) => ({
        ...state,
        listCompany: payload?.data?.items || [],
        loadingCompany: false,
      }))
      .addCase(listPrefetchCompanyAction.rejected, (state) => ({
        ...state,
        listCompany: [],
        loadingCompany: false,
      }))
      .addCase(listPrefetchStoreAction.pending, (state) => ({
        ...state,
        loadingStores: true,
      }))
      .addCase(listPrefetchStoreAction.fulfilled, (state, { payload }) => ({
        ...state,
        listStores: payload?.data?.items || [],
        loadingStores: false,
      }))
      .addCase(listPrefetchStoreAction.rejected, (state) => ({
        ...state,
        listStores: [],
        loadingStores: false,
      }))
      .addCase(listPrefetchUserRolesAction.pending, (state) => ({
        ...state,
        loadingUserRoles: true,
      }))
      .addCase(listPrefetchUserRolesAction.fulfilled, (state, { payload }) => ({
        ...state,
        listUserRoles: payload?.data || [],
        loadingUserRoles: false,
      }))
      .addCase(listPrefetchUserRolesAction.rejected, (state) => ({
        ...state,
        listUserRoles: [],
        loadingUserRoles: false,
      }))
      .addCase(listPrefetchBoothAction.pending, (state) => ({
        ...state,
        loadingBooths: true,
      }))
      .addCase(listPrefetchBoothAction.fulfilled, (state, { payload }) => ({
        ...state,
        listBooths: payload?.data?.items || [],
        loadingBooths: false,
      }))
      .addCase(listPrefetchBoothAction.rejected, (state) => ({
        ...state,
        listBooths: [],
        loadingBooths: false,
      }))
      .addCase(listPrefetchLayoutAction.pending, (state) => ({
        ...state,
        loadingLayouts: true,
      }))
      .addCase(listPrefetchLayoutAction.fulfilled, (state, { payload }) => ({
        ...state,
        listLayouts: payload?.data?.items || [],
        loadingLayouts: false,
      }))
      .addCase(listPrefetchLayoutAction.rejected, (state) => ({
        ...state,
        listLayouts: [],
        loadingLayouts: false,
      }))
      .addCase(listPrefetchThemeAction.pending, (state) => ({
        ...state,
        loadingThemes: true,
      }))
      .addCase(listPrefetchThemeAction.fulfilled, (state, { payload }) => ({
        ...state,
        listThemes: payload?.data?.items || [],
        loadingThemes: false,
      }))
      .addCase(listPrefetchThemeAction.rejected, (state) => ({
        ...state,
        listThemes: [],
        loadingThemes: false,
      }))
      .addCase(listPrefetchLayoutTemplateAction.pending, (state) => ({
        ...state,
        loadingLayoutTemplates: true,
      }))
      .addCase(
        listPrefetchLayoutTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listLayoutTemplates: payload?.data?.items || [],
          loadingLayoutTemplates: false,
        }),
      )
      .addCase(listPrefetchLayoutTemplateAction.rejected, (state) => ({
        ...state,
        listLayoutTemplates: [],
        loadingLayoutTemplates: false,
      }))
      .addCase(listPrefetchThemeTemplateAction.pending, (state) => ({
        ...state,
        loadingThemeTemplates: true,
      }))
      .addCase(
        listPrefetchThemeTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listThemeTemplates: payload?.data?.items || [],
          loadingThemeTemplates: false,
        }),
      )
      .addCase(listPrefetchThemeTemplateAction.rejected, (state) => ({
        ...state,
        listThemeTemplates: [],
        loadingThemeTemplates: false,
      }))
      .addCase(listPrefetchThemeCategoryAction.pending, (state) => ({
        ...state,
        loadingThemeCategories: true,
      }))
      .addCase(
        listPrefetchThemeCategoryAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listThemeCategories: payload?.data?.items || [],
          loadingThemeCategories: false,
        }),
      )
      .addCase(listPrefetchThemeCategoryAction.rejected, (state) => ({
        ...state,
        listThemeCategories: [],
        loadingThemeCategories: false,
      }))
      .addCase(listPrefetchPromotionCampaignAction.pending, (state) => ({
        ...state,
        loadingPromotionCampaigns: true,
      }))
      .addCase(
        listPrefetchPromotionCampaignAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listPromotionCampaigns: payload?.data?.items || [],
          loadingPromotionCampaigns: false,
        }),
      )
      .addCase(listPrefetchPromotionCampaignAction.rejected, (state) => ({
        ...state,
        listPromotionCampaigns: [],
        loadingPromotionCampaigns: false,
      }))

      .addCase(listPrefetchStickerAction.pending, (state) => ({
        ...state,
        loadingThemes: true,
      }))
      .addCase(listPrefetchStickerAction.fulfilled, (state, { payload }) => ({
        ...state,
        listStickers: payload?.data?.items || [],
        loadingThemes: false,
      }))
      .addCase(listPrefetchStickerAction.rejected, (state) => ({
        ...state,
        listStickers: [],
        loadingThemes: false,
      }))
      .addCase(listPrefetchStickerCategoryAction.pending, (state) => ({
        ...state,
        loadingStickerCategories: true,
      }))
      .addCase(
        listPrefetchStickerCategoryAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listStickerCategories: payload?.data?.items || [],
          loadingStickerCategories: false,
        }),
      )
      .addCase(listPrefetchStickerCategoryAction.rejected, (state) => ({
        ...state,
        listStickerCategories: [],
        loadingStickerCategories: false,
      }))
      .addCase(listPrefetchStickerTemplateAction.pending, (state) => ({
        ...state,
        loadingListStickerTemplates: true,
      }))
      .addCase(
        listPrefetchStickerTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listStickerTemplates: payload?.data?.items || [],
          loadingListStickerTemplates: false,
        }),
      )
      .addCase(listPrefetchStickerTemplateAction.rejected, (state) => ({
        ...state,
        listStickerTemplates: [],
        loadingListStickerTemplates: false,
      }))

      // Booth Background
      .addCase(listPrefetchBoothBackgroundAction.pending, (state) => ({
        ...state,
        loadingBoothBackgrounds: true,
      }))
      .addCase(
        listPrefetchBoothBackgroundAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listBoothBackgrounds: payload?.data?.items || [],
          loadingBoothBackgrounds: false,
        }),
      )
      .addCase(listPrefetchBoothBackgroundAction.rejected, (state) => ({
        ...state,
        listBoothBackgrounds: [],
        loadingBoothBackgrounds: false,
      }))

      .addCase(listPrefetchBoothBackgroundTemplateAction.pending, (state) => ({
        ...state,
        loadingListBoothBackgroundTemplates: true,
      }))
      .addCase(
        listPrefetchBoothBackgroundTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listBoothBackgroundTemplates: payload?.data?.items || [],
          loadingListBoothBackgroundTemplates: false,
        }),
      )
      .addCase(listPrefetchBoothBackgroundTemplateAction.rejected, (state) => ({
        ...state,
        listBoothBackgroundTemplates: [],
        loadingListBoothBackgroundTemplates: false,
      }))

      .addCase(listPrefetchBoothBackgroundCategoryAction.pending, (state) => ({
        ...state,
        loadingBoothBackgroundCategories: true,
      }))
      .addCase(
        listPrefetchBoothBackgroundCategoryAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listBoothBackgroundCategories: payload?.data?.items || [],
          loadingBoothBackgroundCategories: false,
        }),
      )
      .addCase(listPrefetchBoothBackgroundCategoryAction.rejected, (state) => ({
        ...state,
        listBoothBackgroundCategories: [],
        loadingBoothBackgroundCategories: false,
      }))

      // Capture position
      .addCase(listPrefetchCapturePositionAction.pending, (state) => ({
        ...state,
        loadingListCapturePositions: true,
      }))
      .addCase(
        listPrefetchCapturePositionAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listCapturePositions: payload?.data?.items || [],
          loadingListCapturePositions: false,
        }),
      )
      .addCase(listPrefetchCapturePositionAction.rejected, (state) => ({
        ...state,
        listCapturePositions: [],
        loadingListCapturePositions: false,
      }))
      .addCase(listPrefetchCapturePositionTemplateAction.pending, (state) => ({
        ...state,
        loadingCapturePositionTemplates: true,
      }))
      .addCase(
        listPrefetchCapturePositionTemplateAction.fulfilled,
        (state, { payload }) => ({
          ...state,
          listCapturePositionTemplates: payload?.data?.items || [],
          loadingCapturePositionTemplates: false,
        }),
      )
      .addCase(listPrefetchCapturePositionTemplateAction.rejected, (state) => ({
        ...state,
        listCapturePositionTemplates: [],
        loadingCapturePositionTemplates: false,
      }));
  },
});

export const { setPrefetchStateAction, resetPrefetchStateAction } =
  prefetchSlice.actions;

export const prefetchReducer = prefetchSlice.reducer;
